import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { API_CALL_STATUS } from "@/constant/config";
import rentalNewsApi from "@/api/common/rentalNewsApi";
import { editorConfig, tinyEditorInit } from '@/constant/config';
import countryList from "@/constant/countryList";
import Editor from '@tinymce/tinymce-vue';

export default {

    components: {
        'editor': Editor,
        CaretTop,
        CaretBottom,
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    extends: baseComponent,
    data() {
        return {
            countryList: Object.keys(countryList).map(item => {
                return {
                    value: item,
                    label: countryList[item],
                    image: `/static/flag/${item.toLowerCase()}.png`
                };
            }),
            isCheckAll: false,
            listData: [],
            isLoading: false,
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.CreatedAt',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
            },
            editNewsModel: null,
            dialogVisible: false,
            tinyEditorInit: {
                ...tinyEditorInit,
                height: 300,
                file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { text: 'iRender Vietnam JSC' });
                        });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { alt: 'iRender Vietnam JSC' });
                        });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { text: 'iRender Vietnam JSC' });
                        });
                    }
                }
            },
        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
        })
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        this.getData();
    },

    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            this.isLoading = true;
            rentalNewsApi.getDataView({
                querySearch: this.pagination.querySearch,
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
            }).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this, 'listData', res.data.data.data);
                        this.$set(this, 'pagination', {
                            ...this.pagination,
                            status: 1,
                            orderBy: res.data.data.pagingItem.orderBy,
                            directionSort: res.data.data.pagingItem.directionSort,
                            pageIndex: res.data.data.pagingItem.pageIndex,
                            pageSize: res.data.data.pagingItem.pageSize,
                            outRowsNumber: res.data.data.pagingItem.outRowsNumber,
                        });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    }
                } catch (error) {
                    console.log("getDataView -> error", error);
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log("getDataView -> error", error);
                this.feedStatusLoading(API_CALL_STATUS.FAILED);
                this.isLoading = false;
            });
        },
        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
                case 'add':
                    this.addItem();
                    break;
                case 'delete':
                    this.deleteItem(param.data.id);
                    break;
                case 'changeImportant':
                    this.saveChangeWithoutConfirm(Object.assign(param.data, {}), param.data);
                    break;
                case 'changeShowOnTop':
                    this.saveChangeWithoutConfirm(Object.assign(param.data, {}), param.data);
                    break;
            }
        },
        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },
        editItem(item) {
            this.editNewsModel = Object.assign({
                ...item,
                countryCodes: (typeof item.countryCodesApply !== 'undefined' && item.countryCodesApply !== null && item.countryCodesApply !== '') ? item.countryCodesApply.split(',') : []
            }, {});
            this.dialogVisible = true;
            setTimeout(() => {
                $("#ckEditorArea .cke_contents").height($("#ckEditorArea").height() - $('#ckEditorArea .cke_top').first().height() - $('#ckEditorArea .cke_bottom').first().height() - 20);
            }, 500);
        },
        onEditorReady(editor) {
            setTimeout(() => {
                $("#ckEditorArea .cke_contents").height($("#ckEditorArea").height() - $('#ckEditorArea .cke_top').first().height() - $('#ckEditorArea .cke_bottom').first().height() - 20);
            }, 500);
        },
        tinyEditorShow() {
            $("#ckEditorArea .tox.tox-tinymce").height($("#ckEditorArea").height());
        },
        selectFileWithCKFinder(elementId) {
            window.selectFileWithCKFinder((fileUrl) => {
                this.$set(this, "notificationImageUrl", fileUrl);
            });
        },
        saveChangeWithoutConfirm(editNewsModel, element) {
            this.isLoading = true;
            rentalNewsApi.insertOrUpdate(editNewsModel).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.dialogVisible = false;
                        this.editNewsModel = null;
                        this.getData();
                    }
                } catch (error) {
                    console.log("saveChangeWithoutConfirm -> error", error);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log("saveChangeWithoutConfirm -> error", error);
                this.isLoading = false;
            });
        },
        saveChange() {
            this.$confirm('This will permanently save the news. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.isLoading = true;
                if (!this.editNewsModel.urL_TEXT || this.editNewsModel.urL_TEXT === null || this.editNewsModel.urL_TEXT === '') {
                    this.editNewsModel.urL_TEXT = 'Read more';
                }
                if(this.editNewsModel.countryCodes && this.editNewsModel.countryCodes.length > 0){
                    this.editNewsModel.countryCodesApply = this.editNewsModel.countryCodes.join(',');
                }
                else this.editNewsModel.countryCodesApply = null;
                rentalNewsApi.insertOrUpdate(this.editNewsModel).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$message({
                                message: 'The news was modified successful.',
                                type: 'success'
                            });
                            this.dialogVisible = false;
                            this.editNewsModel = null;
                            this.getData();
                        }
                    } catch (error) {
                        console.log("insertOrUpdate -> error", error);
                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.log("insertOrUpdate -> error", error);
                    this.isLoading = false;
                });
            }).catch(() => { });
        },
        deleteById(item) {
            this.$confirm('This will permanently delete the news. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.isLoading = true;
                rentalNewsApi.deleteById(item.id).then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$message({
                                message: 'The news was deleted successful.',
                                type: 'success'
                            });
                            this.getData();
                        }
                    } catch (error) {
                        console.log("deleteById -> error", error);
                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.log("deleteById -> error", error);
                    this.isLoading = false;
                });
            }).catch(() => { });
        }
    }
}