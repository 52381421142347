<template>
    <div>
        <div class="card p-2 pl-3 pr-3 mb-3"
                 v-if="nasClientImageInfo && nasClientImageInfo.length > 0">
            <div>
                <strong class="text-primary">STORAGES INFOMATION</strong> <span v-if="lastUpdate">(Last update: {{lastUpdate | pretyDate}})</span>
            </div>

            <div class="nasClientImageInfo d-block d-md-flex flex-wrap"
                style="margin-left: -12px; margin-right: -12px;">
                <div :style="`padding: 12px; min-width: 20rem; max-width: ${$root.windowWidth >= 1000 ? 25 : 100}%; flex: 1 1; `"
                     v-for="(storageItem, storageItemIndex) in nasClientImageInfo" :key="storageItemIndex">
                    <el-card class="box-card" 
                             :style="`background: ${(storageItem.isError ? '#ffabb2' : '#fff' )};`"
                             :body-style="{ padding: '0' }">
                        <div slot="header" class="clearfix p-2 pl-3 pr-3 text-nowrap overflow-hidden">
                            <strong><i class="far fa-hdd text-orange mr-1"></i>{{storageItem.volumeLabel}}</strong>
                        </div>
                        <div class="p-2 pl-3 pr-3">
                            <div class="d-flex">
                                <div class="flex-fill pr-1">
                                    <div>Used <strong>{{storageItem.totalUsedFormated}}</strong></div>
                                    <div class="text-nowrap" v-if="!storageItem.isError">
                                        Free <strong>{{storageItem.availableFreeSpaceFormated}}</strong>
                                    </div>
                                    <div v-else class="text-danger"><strong>Error</strong></div>
                                </div>
                                <div class="flex-fill pl-1">
                                    <div><strong></strong></div>
                                    <div class="text-nowrap" v-if="!storageItem.isError">
                                        Total <strong>{{storageItem.totalSizeFormated}}</strong>
                                    </div>
                                    <div v-else class="text-danger"><strong>Error</strong></div>
                                </div>
                            </div> 
                            <div class="mt-2">
                                <el-progress :text-inside="true" :stroke-width="20" 
                                             text-color="#fff"
                                             v-if="!storageItem.isError"
                                             :percentage="getPercentage(storageItem)" 
                                             :status="getStatusStyle(storageItem)"/>
                                <div v-else class="text-danger" style="height: 1.1rem; top: -5px; position: relative;">
                                    <strong>Please examine me...</strong>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .nasClientImageInfo .el-card__header{
        padding: 0;
    }
</style>
<script>
    import vmServerApi from '@/api/gpuHub/vmServer';
    export default {
        data() {
            return {
                isLoading: false,
                nasClientImageInfo: [],
                intervalLoad: null,
                lastUpdate: null,
            };
        },
        mounted() {
            this.getClientImageStorageInfo();
            this.intervalLoad = setInterval(() => {
                this.getClientImageStorageInfo();
            }, 30000);
        },
        beforeDestroy() {
            clearInterval(this.intervalLoad);
        },
        methods: {
            getClientImageStorageInfo() {
                this.isLoading = true;
                vmServerApi.getClientImageStorageInfo().then((response) => {
                    console.log(response);
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.lastUpdate = new Date();
                        this.nasClientImageInfo = response.data.data;
                    }
                    else {
                        //this.nasClientImageInfo = [];
                        if (response.data  && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.error(error);
                    //this.nasClientImageInfo = [];
                    this.isLoading = false;
                });
            },
            getColorPercent(value) {
                try {
                    if (value > 80) return 'warning';
                    if (value > 95) return 'exception';
                    return 'primary';
                } catch {
                    return 'primary';
                }
            },
            getPercentage(storageItem) {
                try {
                    return (Math.round((100 - (storageItem.availableFreeSpace / storageItem.totalSize) * 100) * 100) / 100);
                } catch (error) {
                    console.error(error);
                    return 0;
                }
            },
            getStatusStyle(storageItem) {
                try {
                    return this.getColorPercent((Math.round((100 - (storageItem.availableFreeSpace / storageItem.totalSize) * 100) * 100) / 100));
                } catch (error) {
                    console.error(error);
                    return '#d0d0d0';
                }
            }
        }
    }
</script>